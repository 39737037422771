<template>
  <div class="container-fluid mb-4 pb-4">
    <strong>รายชื่อสมาชิก</strong>
    <div class="card">

      <div class="d-flex justify-content-between align-items-center alert-secondary">
        <ol class="breadcrumb py-1 m-0">
          <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
            <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
            <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
          </li>
        </ol>
        <div style="width: 250px;" class="p-2">
          <input type="text" v-model="filter" class="form-control form-control-sm" placeholder="ค้นหา...">
        </div>
      </div>

      <b-table
        class="table-account"
        :fields="fields"
        :items="items"
        :show-empty="true"
        empty-text="ไม่มีข้อมูล"
        hover
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #cell(index)="data">
          {{ (perPage*(currentPage-1)) + data.index + 1 }}
        </template>
        <template #cell(username)="data">
          <span>
            <a v-if="data.item.role==='Agent'" href="#" @click="toggleChildAccount(data.item._id)">{{ data.item.user.username }}</a>
            <span v-else>{{ data.item.user.username }}</span>
          </span>
        </template>
        <template #cell(role)="data">
          <div>
            <AccountRole :role="data.item.role" /><br />
            <small v-if="data.item.role==='Agent'">{{ accountLevel(data.item.levelId) }}</small>
          </div>
        </template>
        <template #cell(firstName)="data">
          {{ data.item.user.firstName }}
        </template>
        <template #cell(lastName)="data">
          {{ data.item.user.lastName || '-' }}
        </template>
        <template #cell(createdAt)="data">
          <span>{{ dateFormat(data.item.createdAt, 'DD/MM/YYYY') }}<br />{{ dateFormat(data.item.createdAt, 'HH:mm:ss') }}</span>
        </template>
        <template #cell(lastActive)="data">
          <span>{{ dateFormat(data.item.user.lastActive, 'DD/MM/YYYY') }}<br />{{ dateFormat(data.item.user.lastActive, 'HH:mm:ss') }}</span>
        </template>
        <template #cell(balance)="data">
          <span class="text-success font-weight-bold">{{ balanceFormat(data.item.balance) }}</span>
        </template>
        <template #cell(manage)="data">
          <b-dropdown class="m-0 p-0 btn-edit" size="sm" variant="link" right>
            <template slot="button-content">
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item @click="viewAccount(data.item)">
              <template>
                <span class="text-primary"><i class="far fa-eye"></i> รายละเอียด</span>
              </template>
            </b-dropdown-item>
            <b-dropdown-item v-if="currentAccountId===data.item.createdBy" @click="editProfile(data.item)">
              <template>
                <span class="text-primary"><i class="far fa-address-card"></i> แก้ไขข้อมูล</span>
              </template>
            </b-dropdown-item>
            <b-dropdown-item v-if="currentAccountId===data.item.createdBy" @click="changePassword(data.item)">
              <template>
                <span class="text-primary"><i class="fas fa-key"></i> เปลี่ยนรหัสผ่าน</span>
              </template>
            </b-dropdown-item>
            <b-dropdown-item v-if="currentAccountId===data.item.createdBy" @click="deposit(data.item)">
              <template>
                <span class="text-success"><i class="far fa-plus-square"></i> เติมเงิน</span>
              </template>
            </b-dropdown-item>
            <b-dropdown-item v-if="currentAccountId===data.item.createdBy" @click="withdraw(data.item)">
              <template>
                <span class="text-warning"><i class="far fa-minus-square"></i> ถอนเงิน</span>
              </template>
            </b-dropdown-item>
            <b-dropdown-divider v-if="currentAccountId===data.item.createdBy"></b-dropdown-divider>
            <b-dropdown-item v-if="currentAccountId===data.item.createdBy" @click="blockAccount(data.item)">
              <template>
                <span class="text-danger"><i class="fas fa-ban"></i> ระงับ</span>
              </template>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="items.length"
      :per-page="perPage"
      align="right"
      size="md"
      class="my-0"
    ></b-pagination>

    <ChangePasswordModal :data="changePasswordItem" :is-show="isShowPasswordModal" @close="modalClosed" />
    <EditProfileModal :data="editItem" :is-show="isShowEditModal" @close="modalClosed" />
    <BlockAccountModal :data="blockItem" :is-show="isShowBlockModal" @close="modalClosed" />
    <DepositModal :data="depositItem" :is-show="isShowDepositModal" @close="modalClosed" />
    <WithdrawModal :data="withdrawItem" :is-show="isShowWithdrawModal" @close="modalClosed" />
  </div>
</template>
<script>
import ManageAccountService from '@/services/ManageAccountService'
import moment from '@/helpers/moment'
import {Roles} from '@/configs/account.config'

import ChangePasswordModal from './components/ChangePasswordModal'
import EditProfileModal from './components/EditProfileModal'
import BlockAccountModal from './components/BlockAccountModal'
import DepositModal from './components/DepositModal'
import WithdrawModal from './components/WithdrawModal'

export default {
  name: 'ManageAccount',
  components: {
    ChangePasswordModal,
    EditProfileModal,
    BlockAccountModal,
    DepositModal,
    WithdrawModal
  },
  data() {
    return {
      Accounts: [],
      fields: [
        {key: 'index', label: 'ลำดับ'},
        {key: 'username', label: 'ชื่อผู้ใช้'},
        {key: 'role', label: 'ระดับ'},
        {key: 'firstName', label: 'ชื่อ'},
        {key: 'lastName', label: 'นามสกุล'},
        {key: 'createdAt', label: 'วันที่สมัคร'},
        {key: 'lastActive', label: 'ใช้งานล่าสุด'},
        {key: 'balance', label: 'ยอดเงิน', tdClass: "text-right", thClass: "text-right"},
        {key: 'manage', label: '', tdClass: "text-right"}
      ],
      perPage: 20,
      currentPage: 1,
      filter: '',
      Breadcrumbs: [],
      /**
       * เปลี่ยรหัสผ่าน
       */
      isShowPasswordModal: false,
      changePasswordItem: {
        _id: null,
        username: null,
        nPassword: null,
        cPassword: null
      },
      /**
       * แก้ไขข้อมูลส่วนตัว
       */
      isShowEditModal: false,
      editItem: {
        _id: null,
        username: null,
        firstName: null,
        lastName: null,
        tel: null,
        lineId: null
      },
      /**
       * ระงับการใช้งาน
       */
      isShowBlockModal: false,
      blockItem: {
        _id: null,
        username: null,
        blockNote: null
      },
      /**
       * เติมเงิน
       * @type {Object}
       */
      isShowDepositModal: false,
      depositItem: null,
      /**
       * ถอนเงิน
       * @type {Object}
       */
      isShowWithdrawModal: false,
      withdrawItem: null
    }
  },
  computed: {
    items() {
      return this.Accounts.map((a)=>{
        return {
          ...a,
          user: a.users[0]
        }
      })
    },
    agentLevels() {
      return this.$store.state.accountLevels
    },
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    }
  },
  methods: {
    loadAccounts() {
      ManageAccountService.getAccounts()
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.Accounts = response.data.accounts
          this.Breadcrumbs = response.data.parents
        }
      })
    },
    toggleChildAccount(id) {
      console.log(id)
      ManageAccountService.loadChildAccount(id)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.Accounts = response.data.accounts
          this.Breadcrumbs = response.data.parents
        }
      })
    },
    dateFormat(date, format='YYYY-MM-DD') {
      if(date) {
        return moment(date).format(format)
      }else{
        return '-'
      }
    },
    balanceFormat(balance) {
      return balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    onFiltered() {
      this.currentPage = 1
    },
    accountLevel(_id) {
      const level = this.agentLevels.find((l)=>{
        return l._id === _id
      })
      return level?.levelName || ''
    },
    editProfile(item) {
      this.editItem = {
        ...this.editItem,
        ...item?.user
      }
      console.log(this.editItem)
      this.isShowEditModal = true
    },
    changePassword(item) {
      console.log(item)
      this.changePasswordItem = {
        ...this.changePasswordItem,
        _id: item.user._id,
        username: item.user.username
      }
      this.isShowPasswordModal = true
    },
    blockAccount(item) {
      this.blockItem = {
        _id: item._id,
        username: item.user.username,
        blockNote: null
      }
      this.isShowBlockModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      this.isShowEditModal = false
      this.isShowPasswordModal = false
      this.isShowBlockModal = false
      this.isShowDepositModal = false
      this.isShowWithdrawModal = false
      if(needReload) {
        this.loadAccounts()
      }
    },
    deposit(item) {
      console.log(item)
      this.depositItem = item
      this.isShowDepositModal = true
    },
    withdraw(item) {
      console.log(item)
      this.withdrawItem = item
      this.isShowWithdrawModal = true
    },
    viewAccount(item) {
      this.$router.push({
        name: 'ManageAccountMember',
        params: {
          accountId: item._id
        }
      })
    }
  },
  mounted() {
    this.loadAccounts()
  }
}
</script>
<style lang="scss">
.table-account {
  margin-bottom: 0;
  thead {
    tr {
      th {
        font-size: 90%;
        //font-weight: normal;
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        text-align: center;
        font-size: 90%;

        .badge {
          font-size: 90%;
          font-weight: normal;
        }
      }
    }
  }
  button.dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu.dropdown-menu-right {
    li {
      font-size: 90%;
    }
  }
}
</style>
<style lang="scss" scoped>
.breadcrumb {
  background-color: transparent;
  border-radius: 0;
  border-bottom: 0;
}
</style>
